export const HOME = "/";
export const ABOUT = "/about";
export const LOGIN = "/login";
export const COURSE = "/course";
export const PAYMENT_RESPONSE = "/payment-status";
export const COURSE_DETAIL = "/detail";
export const CONTACT_US = "/contact-us";
export const VIDEO_SESSION = "/my-learning";
export const PATH_BLOGS = "/itt-blogs";
export const PATH_BLOG_DETAILS = "/blog-details";
